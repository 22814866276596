.app-tour-tooltip-directive-disable-pointer-events {
  pointer-events: none !important;
}

.app-tour-tooltip-directive-background-color {
  background-color: hsl(0deg 0% 40% / 65%) !important;
}

.app-tour-tooltip-directive-transition {
  transition: filter 0.4s ease-in-out, background-color 0.4s ease-in-out !important;
}

.app-tour-tooltip-directive-dim {
  filter: brightness(0.4) !important;
}
