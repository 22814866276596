@import "variables";
@import "components/tour-tooltip";
@import "libraries/quill";
@import "libraries/material-cdk";
@import "utils/functions";
@import "utils/animations";

* {
  box-sizing: border-box;
}

:root {
  --teams-red: #{$teams-red};
  --teams-green: #{$teams-green};
  --teams-magenta: #{$teams-magenta};
  --teams-grey: #{$teams-grey};
  --teams-yellow: #{$teams-yellow};
  --bnear-brand: #{$bnear-brand};
  --bnear-danger: #{$bnear-danger};
  --bnear-light-bg: #{$bnear-light-bg};
}

body,
html {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  font: normal 16px/1.2 $font-stack !important;
  user-select: none;
}

img {
  pointer-events: none;
}

button,
input {
  font-family: $font-stack;
}

input {
  color: $bnear-brand;
}

@font-face {
  font-family: "Mazzard Soft";
  src:
    url("/assets/fonts/mazzard-soft-h-regular.woff2") format("woff2"),
    url("/assets/fonts/mazzard-soft-h-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mazzard Soft";
  src:
    url("/assets/fonts/mazzard-soft-h-medium.woff2") format("woff2"),
    url("/assets/fonts/mazzard-soft-h-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mazzard Soft";
  src:
    url("/assets/fonts/mazzard-soft-h-semibold.woff2") format("woff2"),
    url("/assets/fonts/mazzard-soft-h-semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src:
    url("/assets/fonts/segeo-ui-300.woff2") format("woff2"),
    url("/assets/fonts/segeo-ui-300.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src:
    url("/assets/fonts/segeo-ui-400.woff2") format("woff2"),
    url("/assets/fonts/segeo-ui-400.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src:
    url("/assets/fonts/segeo-ui-600.woff2") format("woff2"),
    url("/assets/fonts/segeo-ui-600.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src:
    url("/assets/fonts/segeo-ui-700.woff2") format("woff2"),
    url("/assets/fonts/segeo-ui-700.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
