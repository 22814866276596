@use "sass:color";

// Fonts
$font-stack: "Mazzard Soft",
  system-ui,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  sans-serif;
$font-stack-teams: "Segoe UI",
  system-ui,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  sans-serif;

// Teams colors
$teams-red: #d13438;
$teams-yellow: #ffd100;
$teams-green: #13a10e;
$teams-grey: #616161;
$teams-magenta: #c239b3;

// bNear colors
$bnear-brand: #494b83;
$bnear-brand-rgb: 73, 75, 131;
$bnear-brand-hover: color.adjust($bnear-brand, $blackness: -12%);
$bnear-brand-active: color.adjust($bnear-brand, $blackness: 5%);
$bnear-danger: #ff1900;
$bnear-light-bg: #f2f3ff;
$bnear-extra-light-bg: #f9f9ff;
$bnear-tooltip-bounce-animation: tooltip-bounce 1s ease-in-out 0ms infinite alternate-reverse;
$bnear-tooltip-left-right-bounce-animation: tooltip-bounce-left-right 1s ease-in-out 0ms infinite alternate-reverse;

// Misc variables
$low-z-index: 900;
$medium-z-index: 1000;
$medium-high-z-index: 1050;
$high-z-index: 1100;
