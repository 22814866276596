// If this is updated, style.util.ts and office.util.ts also need to be updated
:root {
  --scale-factor: min(1vw, 1.75vh);

  @media only screen and (max-width: 600px) {
    --scale-factor: 1.8vw;
  }

  @media only screen and (max-width: 500px) {
    --scale-factor: 2.5vw;
  }

  @media only screen and (max-width: 400px) {
    --scale-factor: 2.4vw;
  }
}

@function scale-by-office($base-value, $min-value: "unset", $max-value: "unset") {
  @if $max-value == "unset" {
    @if $min-value == "unset" {
      @return calc(#{$base-value} * var(--scale-factor));
    }

    @return max(calc(#{$base-value} * var(--scale-factor)), #{$min-value});
  }

  @if $min-value == "unset" {
    @return min(#{scale-by-office($base-value)}, #{$max-value});
  }

  @return max(min(#{scale-by-office($base-value)}, #{$max-value}), #{$min-value});
}
