@keyframes tooltip-bounce {
  from {
    transform: translateY(-1px);
  }

  to {
    transform: translateY(3px);
  }
}

@keyframes tooltip-bounce-left-right {
  from {
    transform: translateX(-1px);
  }

  to {
    transform: translateX(3px);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes shine {
  from {
    left: -80%;
  }

  to {
    left: 100%;
  }
}
