@import "@angular/cdk/overlay-prebuilt.css";
@import "src/styles/variables";
@import "src/styles/utils/animations";
@import "src/styles/utils/functions";

// Fix positioning on iPadOS
.cdk-overlay-container,
.cdk-overlay-connected-position-bounding-box {
  right: 0;
  bottom: 0;
}

.cdk-global-overlay-wrapper {
  z-index: calc($high-z-index + 2);
}

.dialog-default-backdrop {
  backdrop-filter: blur(4px) !important;
  background-color: rgba($color: black, $alpha: 75%);
  z-index: calc($high-z-index + 1);
}

.low-z-index {
  z-index: $low-z-index;
}

.medium-z-index {
  z-index: $medium-z-index;
}

.medium-high-z-index {
  z-index: $medium-high-z-index;
}

.high-z-index {
  z-index: $high-z-index;
}

.cdk-overlay-container {
  z-index: $low-z-index;
}

.cdk-overlay-pane.overlay-push-required {
  position: fixed !important;
  inset: 0 !important;
  margin: auto;
  width: fit-content;
  height: fit-content;
}
